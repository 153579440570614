<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-between">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="d-inline-flex mr-1"
                  variant="outline-primary"
                  :to="{name: 'startup-portal-details'}"
                >
                  <feather-icon
                    class="mr-50"
                    icon="PlusIcon"
                  />
                  <span class="align-middle text-nowrap">Add Startup</span>
                </b-button>
              </div>
            </b-form-group>

          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="filteredRows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: URL -->
              <span
                v-if="props.column.field === 'title'"
                class="text-nowrap"
              >
                {{ props.row.users_organizationtable.title }}
                <b-link
                  v-b-tooltip:hover="'View Details'"
                  class="ml-50"
                  size="sm"
                  variant="flat-primary"
                  @click="$showEntityModal(props.row.id)"
                ><feather-icon icon="InfoIcon" /></b-link>
              </span>
              <span
                v-if="props.column.field === 'designation'"
              >

                <span v-if="props.row.editable">
                  <b-input-group>
                    <b-form-input
                      id="updateDesignation"
                      v-model="designation"
                      placeholder="Designation"
                    />
                    <template #append>
                      <b-button
                        v-b-tooltip="'Update'"
                        size="sm"
                        variant="outline-primary"
                        @click="props.row.editable = !props.row.editable; editDesignation(props.row.id)"
                      >
                        <feather-icon
                          icon="CheckIcon"
                        />
                      </b-button>
                    </template>
                  </b-input-group>
                  <small v-if="props.row.editable && props.row.designation !== designation">Previous: {{
                    props.row.designation
                  }}</small>
                </span>
                <span
                  v-else
                  class="d-flex justify-content-between"
                > {{ props.row.designation }} <b-button
                  v-b-tooltip="'Edit'"
                  size="sm"
                  variant="flat-secondary"
                  @click="designation = props.row.designation; props.row.editable = !props.row.editable"
                >
                  <feather-icon
                    icon="Edit2Icon"
                  />

                </b-button></span>

              </span>
              <span
                v-else-if="props.column.field === 'url'"
                class="text-nowrap"
              >
                <b-link
                  :href="prependHttps(props.row.url)"
                  target="_blank"
                >{{ props.row.url }}</b-link>
              </span>

              <span v-else-if="props.column.field === 'buttons'">
                <b-button
                  variant="outline-primary"
                  size="sm"
                  :to="{name: 'startup-portal-details', params: {id: props.row.id}}"
                >Update Details</b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCard, BFormGroup, BFormInput, BFormSelect, BInputGroup, BLink, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BLink,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BButton,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: null,
      designation: '',
      columns: [
        {
          label: 'Organisation',
          field: 'title',
        },
        {
          label: 'Designation',
          field: 'designation',
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },
      ],
      startups: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },

  computed: {
    ...mapGetters({
      userDetails: 'user/getUserDetails',
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filteredRows() {
      if (!this.filter) return this.startups
      return this.startups.filter(startup => startup.type.toLowerCase() === this.filter)
    },
  },
  methods: {
    editDesignation(assocId) {
      if (!this.designation) return this.showAlert('Designation cannot be empty', 'danger')
      const oldDesgination = this.startups.find(e => e.id === assocId).designation
      if (oldDesgination === this.designation) return false
      this.$apollo.mutate({
        mutation: gql`
        mutation updateDesignation($id: Int!, $designation: String!) {
          update_users_associationtable_by_pk(pk_columns: {id: $id}, _set: {designation: $designation}) {
            id
          }
        }`,
        variables: {
          id: assocId,
          designation: this.designation,
        },
        update: ({ data: { update_users_associationtable_by_pk } }) => {
          if (update_users_associationtable_by_pk?.id) {
            this.showAlert('Designation updated successfully', 'success')
          } else {
            this.showAlert('Failed to update successfully', 'success')
          }
          this.$apollo.queries.startups.refetch()
        },
      })
      return true
    },

  },
  apollo: {
    startups: {
      query() {
        return gql`{
          users_associationtable(where: {role: {_eq: "startup"}, incubator_id: {_is_null: true}, user_id: {_eq: "${this.userDetails.id}"}}) {
            id
            organization_id
            program_id
            designation
            users_organizationtable {
              title
            }
          }
        }`
      },
      update: data => data.users_associationtable.map(item => ({
        ...item,
        editable: false,
      })),
    },
  },
}
</script>
